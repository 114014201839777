<template>
  <AppContainer id="tickets" class="my-5">
    <b-form @submit.prevent="upload">
      <b-row>
        <b-col cols md="12" class="my-3">
          <h4 class="lead text-center">Add New Ticket</h4>
        </b-col>
        <b-col cols md="12" class="ml-auto mr-auto">
          <b-row>
            <b-col cols md="6">
              <b-form-group id="input-group-1" label-for="item1-input">
                <b-form-input
                  v-model.trim="ticket.item1"
                  id="item1-input"
                  required
                  placeholder="Item e.g. MONDAY, TUESDAY, AND THURSDAY"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols md="6">
              <b-form-group id="input-group-1" label-for="para1-input">
                <b-form-input
                  v-model.trim="ticket.para1"
                  id="para1-input"
                  required
                  placeholder="Price 1 e.g. #1,000 before 5pm"
                ></b-form-input>
              </b-form-group>
            </b-col>
            
            <b-col cols md="6">
              <b-form-group id="input-group-2" label-for="item2-input">
                <b-form-input
                  v-model.trim="ticket.item2"
                  id="item2-input"
                  required
                  placeholder="Item"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols md="6">
              <b-form-group id="input-group-2" label-for="para2-input">
                <b-form-input
                  v-model.trim="ticket.para2"
                  id="para2-input"
                  required
                  placeholder="Price"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols md="6">
              <b-form-group id="input-group-3" label-for="item3-input">
                <b-form-input
                  v-model.trim="ticket.item3"
                  id="item3-input"
                  required
                  placeholder="Item"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols md="6">
              <b-form-group id="input-group-3" label-for="para3-input">
                <b-form-input
                  v-model.trim="ticket.para3"
                  id="para3-input"
                  required
                  placeholder="Price"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols md="6">
              <b-form-group id="input-group-4" label-for="item4-input">
                <b-form-input
                  v-model.trim="ticket.item4"
                  id="item4-input"
                  required
                  placeholder="Item"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols md="6">
              <b-form-group id="input-group-4" label-for="para4-input">
                <b-form-input
                  v-model.trim="ticket.para4"
                  id="para4-input"
                  required
                  placeholder="Price"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols md="6">
              <b-form-group id="input-group-5" label-for="item5-input">
                <b-form-input
                  v-model.trim="ticket.item5"
                  id="item5-input"
                  required
                  placeholder="Item"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols md="6">
              <b-form-group id="input-group-5" label-for="para5-input">
                <b-form-input
                  v-model.trim="ticket.para5"
                  id="para5-input"
                  required
                  placeholder="Price"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols md="6">
              <b-form-group id="input-group-6" label-for="item6-input">
                <b-form-input
                  v-model.trim="ticket.item6"
                  id="item6-input"
                  required
                  placeholder="Item"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols md="6">
              <b-form-group id="input-group-6" label-for="para6-input">
                <b-form-input
                  v-model.trim="ticket.para6"
                  id="para6-input"
                  required
                  placeholder="Price"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols md="12" class="mb-1">
              <b-form-group id="input-group-1" label-for="picture-input">
                <b-form-file
                  accept="image/png, image/jpeg"
                  @change="handleFileChange($event)"
                ></b-form-file>
              </b-form-group>
            </b-col>

            <b-col cols md="12" class="mb-1">
              <section>
                <ul v-if="errors.length > 0">
                  <li v-for="(error, index) in errors" :key="index">
                    {{ error }}
                  </li>
                </ul>
              </section>
            </b-col>

            <b-col cols md="12" class="mb-1" v-show="showProgress">
              <h4 class="lead">Saving...</h4>
              <b-progress
                :value="progress"
                max="100"
                show-progress
                animated
              ></b-progress>
            </b-col>
          </b-row>

          <b-col cols md="12" class="my-1">
            <b-button type="submit" variant="primary" :disabled="!validate"
              >Save ticket</b-button
            >
          </b-col>
        </b-col>
      </b-row>
    </b-form>
  </AppContainer>
</template>

<script>
const AppContainer = () => import('@/core/Components/App/AppContainer.vue')
import { api } from '../../resource'
import axios from 'axios'
export default {
  name: 'tickets-create',
  data() {
    return {
      results: null,
      errors: [],
      file: null,
      filesSelected: 0,
      cloudName: 'brandscinemas',
      preset: 'brandscinemas',
      tags: 'browser-upload',
      progress: 0,
      showProgress: false,
      fileContents: null,
      formData: null,
      ticket: {
        imgurl: '',
        item1: '',
        item2: '',
        item3: '',
        item4: '',
        item5: '',
        item6: '',
        para1: '',
        para2: '',
        para3: '',
        para4: '',
        para5: '',
        para6: '',
      }
    }
  },
  methods: {
    // function to handle file info obtained from local
    // file system and set the file state
    handleFileChange: function(event) {
      console.log('handlefilechange', event.target.files)
      //returns an array of files even though multiple not used
      this.file = event.target.files[0]
      this.filesSelected = event.target.files.length
    },
    prepareFormData: function() {
      this.formData = new FormData()
      this.formData.append('upload_preset', this.preset)
      this.formData.append('tags', this.tags) // Optional - add tag for image admin in Cloudinary
      this.formData.append('file', this.fileContents)
    },
    // function to handle form submit
    upload() {
      //no need to look at selected files if there is no cloudname or preset
      if (this.preset.length < 1 || this.cloudName.length < 1) {
        this.errors.push('You must enter a cloud name and preset to upload')
        return
      }
      // clear errors
      else {
        this.errors = []
      }
      console.log('upload', this.file.name)
      let reader = new FileReader()
      // attach listener to be called when data from file
      reader.addEventListener(
        'load',
        function() {
          this.fileContents = reader.result
          this.prepareFormData()
          let cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`
          let requestObj = {
            url: cloudinaryUploadURL,
            method: 'POST',
            data: this.formData,
            onUploadProgress: function(progressEvent) {
              console.log('progress', progressEvent)
              this.progress = Math.round(
                (progressEvent.loaded * 100.0) / progressEvent.total
              )
              console.log(this.progress)
              //bind "this" to access vue state during callback
            }.bind(this)
          }
          //show progress bar at beginning of post
          this.showProgress = true
          axios(requestObj)
            .then(response => {
              this.results = response.data
              this.ticket.imgurl = this.results.url
              this.ticket.item1 += '|' + this.ticket.para1
              this.ticket.item2 += '|' + this.ticket.para2
              this.ticket.item3 += '|' + this.ticket.para3
              this.ticket.item4 += '|' + this.ticket.para4
              this.ticket.item5 += '|' + this.ticket.para5
              this.ticket.item6 += '|' + this.ticket.para6

              api
                .create('tickets/create', this.ticket)
                .then(() => {
                  this.$swal('Success', 'Ticket successfully saved!', 'success')
                  this.reset()
                })
                .catch(e => {
                  this.$swal(e.body.status_message, e.body.data, 'error')
                })
            })
            .catch(error => {
              this.errors.push(error)
              console.log(this.error)
            })
            .finally(() => {
              setTimeout(
                function() {
                  this.showProgress = false
                }.bind(this),
                1000
              )
            })
        }.bind(this),
        false
      )
      // call for file read if there is a file
      if (this.file && this.file.name) {
        reader.readAsDataURL(this.file)
      }
    },
    reset() {
      this.ticket.item1 = ''
      this.ticket.item2 = ''
      this.ticket.item3 = ''
      this.ticket.item4 = ''
      this.ticket.item5 = ''
      this.ticket.item6 = ''
      this.ticket.para1 = ''
      this.ticket.para2 = ''
      this.ticket.para3 = ''
      this.ticket.para4 = ''
      this.ticket.para5 = ''
      this.ticket.para6 = ''
      this.filesSelected = 0
      this.file = null
    }
  },
  components: {
    AppContainer
  },
  computed: {
    validate() {
      return (
        this.ticket.item1.length >= 3 &&
        this.ticket.item2.length >= 3 &&
        this.ticket.item3.length >= 3 &&
        this.ticket.item4.length >= 3 &&
        this.ticket.item5.length >= 3 &&
        this.ticket.item6.length >= 3 &&
        this.ticket.para1.length >= 3 &&
        this.ticket.para2.length >= 3 &&
        this.ticket.para3.length >= 3 &&
        this.ticket.para4.length >= 3 &&
        this.ticket.para5.length >= 3 &&
        this.ticket.para6.length >= 3 &&
        this.filesSelected > 0
      )
    }
  }
}
</script>
